import { userApi } from 'src/constants/api';
import { User } from 'src/typings/User';
import { fetchJson } from 'src/utils/fetch';
import { UserApiModel } from './apiModels';

type GetUserApiResponse = UserApiModel;

export async function getUserService(): Promise<User> {
  const response: GetUserApiResponse = await fetchJson(userApi.user());

  return response;
}
