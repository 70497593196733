import { userKeys } from './constants';

export function getUserDataKey(): string[] {
  return [userKeys.data];
}

export function getUserSettingsKey(userId: number): (string | { userId: number })[] {
  return [userKeys.settings, { userId }];
}

export function getBulkUserSettingsKey(): string[] {
  return [userKeys.bulkSettings];
}
