import pluralize from 'pluralize';
import { SubscriptionProductShortName } from 'src/typings/Subscription';
import { MIN_PASSWORD_LENGTH } from './limits';

export const invalidInvitationToken = 'Invalid invitation token';
export const invalidOrganizationName = 'Invalid organization name';
export const passwordsMismatch = 'Make sure your new passwords match';
export const passwordTooShort = `Passwords must be ${MIN_PASSWORD_LENGTH} characters or longer`;
export const termsNotAccepted = 'Please accept the terms and conditions to continue';
export const somethingWentWrong = 'Something went wrong';
export const groupNameisRequired = 'Group name is required';
export const settingsUpdateFailed = 'Unable to update the settings for your device';
export const offlineError = 'This device is currently offline';
export const listDeviceError = `We're having trouble retrieving the status of your Airtame devices,
                                but we'll keep trying.`;
export const invalidDeviceName = 'Invalid device name';
export const invalidUrl = 'Please enter a valid URL';
export const invalidImage = 'Please select a valid image';
export const imageTooBig = 'Images must be under 2MB';
export const imageUploadFailed = 'Upload failed, please try again';
export const getDeviceSettingsError = 'Unable to get device settings';
export const postDeviceTokenError = 'Unable to get device token';
export const postDeviceOOBSettingsError = 'Unable to add new device to Cloud';
export const getSignageTemplatesError = 'Unable to show the Signage Overview';
export const saveAppsSettingsError = 'Unable to save apps settings';
export const getDevicePeripheralsError = 'Unable to get device peripherals';
export const appListingError = 'Unable to get available apps';
export const invalidApPsk = 'Invalid password. Must be at least 8 characters (ASCII)';
export const miracastInvalidEmptyApPsk = `Miracast is enabled.
AP password can't be empty in Miracast mode.`;
export const miracastApError = 'Miracast is enabled. At least 1 access point should be enabled.';
export const miracastError = `Miracast is enabled. At least 1 access point should be enabled
and password protected.`;
export const invalidGooglecast =
  'To use Google Cast, either disable pin code, or enable pin code override.';
export const splashContentTooLong = 'Must be 300 characters or fewer';
export const bulkEditOfflineDevices = `Some of your devices are offline. You can only edit devices
                                       that are online.`;
export const passwordProtectionError = 'The device password can not be empty';
export const unableToChangeAp =
  'You have selected devices whose access points cannot be enabled, since they are connected to ' +
  'both 2.4 and 5.2 GHz wireless networks';
export const unableToChooseResolution =
  `This option is unavailable because there is no common ` +
  `resolution that the selected devices can display.

  To adjust this setting, select a single device.`;
export const unableToChangeEnergySaver = `Energy Saver settings are unavailable as some of the
  devices you have selected are not part of your ${SubscriptionProductShortName.plus} plan.`;
export const unableToValidatePermissions = 'Oops, we had some trouble loading the homescreen page';
export const unableToUpdatePermissions =
  "Unable to update the organization's homescreen permissions";
export const updatAppSettingsError = 'Could not update settings;';
export const googleAuthenticationFailed = 'Google authentication failed. Please try again';
export const microsoftAuthenticationFailed = 'Microsoft authentication failed. Please try again';
export const unableToConnectCalendar = 'Unable to connect to your calendar. Please try again';
export const unableToLoadCalendars = 'We could not load your rooms. Please try re-authenticating';
export const noMeetingRoomsConfigured =
  'We could not retrieve any meeting rooms from your calendar provider. ' +
  'Please ensure you have at least one room configured.';
export const noMSExchangeOnlineErrorCode = 'MailboxNotEnabledForRESTAPI';
export const noMSExchangeSharedOnlineErrorCode = 'RESTAPINotEnabledForComponentSharedMailbox';
export const noMSExchangeOnline = 'Your mailbox must be on Exchange Online.';
export const MSBetaAPIRateLimitExceededErrorCode = 'ApplicationThrottled';
export const MSBetaAPIRateLimitExceeded =
  'We could not get your calendars. Please try again in a few moments';
export const getSubscriptionStatusErrorMessage = 'Unable to get your Cloud organization status';
export const unableToUpdateSubscriptionDevices = 'Unable to update devices for your subscription';
export const startTrialErrorMessage = 'Unable to start trial';
export const cancelSubscriptionErrorMessage = 'Unable to cancel subscription';

export const getBillingHistoryErrorMessage = 'Unable to get billing history';

export const unableToAddPaymentInfo = 'Unable to add payment info';
export const homescreenAppRoomError = `You do not have permission to access the selected room
calendar. To use this calendar, first request access from the calendar owner.`;
export const calendarNoRooms = `You do not have any rooms in your organization`;
export const roomOverviewAppRoomError = unavailableRooms =>
  `You do not have permission to access the following ${pluralize('room', unavailableRooms.length)}:
${unavailableRooms.map(ur => ur.originalRoomName).join(', ')}.`;
export const failedToConfirmPayment = 'Failed to confirm payment';
export const failedToLoadStripe = 'Failed to load payment client, please try again';

export const failedToObtainMassDeploymentKey = 'Failed to obtain deployment key';

// Organization
export const updateOrganizationNameError = 'Failed to update organization name';
export const getOrganizationSettingsError = 'Unable to get organization settings';
export const putOrganizationSettingsError = 'Failed to update organization settings';
export const organizationIdCopyError = 'Error when copying Organization ID to clipboard';
export const updateBillingEmailError = 'Failed to update billing email';
export const updateBillingAddressError = 'Failed to update billing address';

// Subscription
export const postConvertPlusToEduError = 'Failed to convert plus licenses to education licenses';

export const powerpointLoadError = 'An error happened while loading the presentation.';
export const powerpointFileSizeExceeded = 'Error: The file is too big to convert';
