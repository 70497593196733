import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuthStore } from 'src/store/authStore';
import { User, UserRole } from 'src/typings/User';
import { usePostLogoutUser } from './usePostLogoutUser';
import { getUserDataKey } from './utils';

const defaultUser: User = {
  displayName: '',
  email: '',
  id: 0,
  permissions: [],
  role: UserRole.user,
  termsAccepted: true,
};

/**
 * Returns the logged in user data from the cache.
 */
export function useGetUserData(): User {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);

  // we don't specify a query function here to read directly from the cache
  const { data: user } = useQuery<User>({
    queryKey: getUserDataKey(),
    enabled: isAuthenticated,
    staleTime: Infinity,
  });

  const { mutate: logout } = usePostLogoutUser();

  useEffect(() => {
    // the user shouldn't be authenticated without a user object in cache
    if (isAuthenticated && !user) {
      logout();
    }
  }, [user, isAuthenticated]);

  return user ?? defaultUser;
}
