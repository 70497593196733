import { Action } from 'redux';

export enum GoogleService {
  GOOGLE_CALENDAR = 'google-calendar',
  GOOGLE_SLIDES = 'google-slides',
}

export enum ServiceIntegrationActionType {
  AUTHENTICATE_GOOGLE_REQUEST = 'AUTHENTICATE_GOOGLE_REQUEST',
  AUTHENTICATE_GOOGLE_RESPONSE = 'AUTHENTICATE_GOOGLE_RESPONSE',
  AUTHENTICATE_GOOGLE_ERROR = 'AUTHENTICATE_GOOGLE_ERROR',

  GET_GOOGLE_ACCESS_TOKEN_REQUEST = 'GET_GOOGLE_ACCESS_TOKEN_REQUEST',
  GET_GOOGLE_ACCESS_TOKEN_RESPONSE = 'GET_GOOGLE_ACCESS_TOKEN_RESPONSE',
  GET_GOOGLE_ACCESS_TOKEN_ERROR = 'GET_GOOGLE_ACCESS_TOKEN_ERROR',

  AUTHENTICATE_MICROSOFT_REQUEST = 'AUTHENTICATE_MICROSOFT_REQUEST',
  AUTHENTICATE_MICROSOFT_RESPONSE = 'AUTHENTICATE_MICROSOFT_RESPONSE',
  AUTHENTICATE_MICROSOFT_ERROR = 'AUTHENTICATE_MICROSOFT_ERROR',

  GET_MICROSOFT_ACCESS_TOKEN_REQUEST = 'GET_MICROSOFT_ACCESS_TOKEN_REQUEST',
  GET_MICROSOFT_ACCESS_TOKEN_RESPONSE = 'GET_MICROSOFT_ACCESS_TOKEN_RESPONSE',
  GET_MICROSOFT_ACCESS_TOKEN_ERROR = 'GET_MICROSOFT_ACCESS_TOKEN_ERROR',
}

/* eslint-disable max-len */
export type AuthenticateGoogleRequest =
  Action<ServiceIntegrationActionType.AUTHENTICATE_GOOGLE_REQUEST>;
export type AuthenticateGoogleResponse =
  Action<ServiceIntegrationActionType.AUTHENTICATE_GOOGLE_RESPONSE> & {
    googleEmail: string;
    accessToken: string;
  };
export type AuthenticateGoogleError =
  Action<ServiceIntegrationActionType.AUTHENTICATE_GOOGLE_ERROR>;
export type GetGoogleAccessTokenRequest =
  Action<ServiceIntegrationActionType.GET_GOOGLE_ACCESS_TOKEN_REQUEST>;
export type GetGoogleAccessTokenResponse =
  Action<ServiceIntegrationActionType.GET_GOOGLE_ACCESS_TOKEN_RESPONSE> & {
    googleAccessToken: string;
  };
export type GetGoogleAccessTokenError =
  Action<ServiceIntegrationActionType.GET_GOOGLE_ACCESS_TOKEN_ERROR>;

export type AuthenticateMicrosoftRequest =
  Action<ServiceIntegrationActionType.AUTHENTICATE_MICROSOFT_REQUEST>;
export type AuthenticateMicrosoftResponse =
  Action<ServiceIntegrationActionType.AUTHENTICATE_MICROSOFT_RESPONSE> & {
    microsoftEmail: string;
    accessToken: string;
  };
export type AuthenticateMicrosoftError =
  Action<ServiceIntegrationActionType.AUTHENTICATE_MICROSOFT_ERROR>;
export type GetMicrosoftAccessTokenRequest =
  Action<ServiceIntegrationActionType.GET_MICROSOFT_ACCESS_TOKEN_REQUEST>;
export type GetMicrosoftAccessTokenResponse =
  Action<ServiceIntegrationActionType.GET_MICROSOFT_ACCESS_TOKEN_RESPONSE> & {
    microsoftAccessToken: string;
  };
export type GetMicrosoftAccessTokenError =
  Action<ServiceIntegrationActionType.GET_MICROSOFT_ACCESS_TOKEN_ERROR>;
/* eslint-enable max-len */

export type ServiceIntegrationAction =
  | AuthenticateGoogleRequest
  | AuthenticateGoogleResponse
  | AuthenticateGoogleError
  | GetGoogleAccessTokenRequest
  | GetGoogleAccessTokenResponse
  | GetGoogleAccessTokenError
  | AuthenticateMicrosoftRequest
  | AuthenticateMicrosoftResponse
  | AuthenticateMicrosoftError
  | GetMicrosoftAccessTokenRequest
  | GetMicrosoftAccessTokenResponse
  | GetMicrosoftAccessTokenError;
