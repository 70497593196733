import { useSuspenseQuery, UseSuspenseQueryResult } from '@tanstack/react-query';
import { getUserService } from 'src/services/user/getUserService';
import { User } from 'src/typings/User';
import { APIError } from 'src/utils/error';
import { getUserDataKey } from './utils';

/**
 * Returns the logged in user data from the server.
 * This should only be called once at the root of the application.
 *
 * In other cases, we should use the `useGetUserData` hook.
 */
export function useGetUserSuspense(): UseSuspenseQueryResult<User, APIError> {
  return useSuspenseQuery({
    queryKey: getUserDataKey(),
    queryFn: getUserService,
  });
}
