import { DeviceSetupStoreState } from 'src/components/routes/Devices/DeviceSetup/deviceSetupStore/types';

const ns = 'device-setup';

export const deviceSetup = {
  page: `${ns}__page`,
  headerCancelSetupAction: `${ns}__header-cancel-setup-action`,
  cancelSetupModalBackAction: `${ns}__cancel-setup-modal-back-action`,
  cancelSetupModalConfirmAction: `${ns}__cancel-setup-modal-confirm-action`,
  passcodeForm: `${ns}__passcode-form`,
  passcodeFormContinueAction: `${ns}__passcode-form-continue-action`,
  passcodeFormForm: `${ns}__passcode-retry-form`,
  passcodeFormFormTryAgainAction: `${ns}__passcode-retry-form-try-again-action`,
  passcodeFormFormBackAction: `${ns}__passcode-retry-form-back-action`,
  settingsForm: `${ns}__settings-form`,
  settingsFormContinueAction: `${ns}__settings-form-continue-action`,
  settingsFormBackAction: `${ns}__settings-form-back-action`,
  licensePicker: `${ns}__license-picker`,
  licensePickerContinueAction: `${ns}__license-picker-continue-action`,
  licensePickerContinueWithNoLicenseAction: `${ns}__license-picker-continue-without-license-action`,
  successMessage: `${ns}__success-message`,
  successMessageNoLicense: `${ns}__success-message-no-license`,
  successMessageHasLicense: `${ns}__success-message-has-license`,
  successMessageSetupAnotherDeviceAction: `${ns}__success-message-setup-another-device-action`,
  successMessageCustomizeSettingsAction: `${ns}__success-message-done-action`,
  getField: (fieldName: keyof DeviceSetupStoreState['fields']): string =>
    `${ns}__field-${fieldName}`,
  getFieldError: (fieldName: keyof DeviceSetupStoreState['fields']): string =>
    `${ns}__field-${fieldName}-error`,
};
