import { WeekDaysType } from 'src/constants/weekdays';
import {
  DeviceActivationPlanApiModel,
  DeviceActivationStatusApiModel,
  DeviceApiModel,
  DeviceCommandApiModel,
  DeviceGetTokenApiModel,
  DeviceInfoApiModel,
  DeviceLatestVersionApiModel,
  DeviceNetworkInterfaceApiModel,
  DeviceNetworkStateApiModel,
  DevicePeripheralsApiModel,
  DevicePlatformApiModel,
  DeviceSettingsStateApiModel,
  DeviceStateApiModel,
  DeviceVersionStatusApiModel,
  FirmwareChannelApiModel,
  GetDeviceActivationApiResponse,
  HomescreenOrientationApiModel,
  NetworkFrequencyBandApiModel,
  PostDeviceCommandApiResponse,
  PostDeviceOOBSettingsApiRequest,
  ResolutionApiModel,
  ScheduleAutoRestartConfigApiModel,
  ScheduleAutoRestartDisabledConfigApiModel,
  ScheduleAutoRestartModeApiModel,
  ScheduleAutoRestartScheduledConfigApiModel,
  ScheduleAutoUpdateWeekDaysApiModel,
  ScheduleStandbyAutomaticConfigApiModel,
  ScheduleStandbyConfigApiModel,
  ScheduleStandbyDisabledConfigApiModel,
  ScheduleStandbyModeApiModel,
  ScheduleStandbyScheduledConfigApiModel,
  ScheduleStandbyWeekDaysPropsApiModel,
  ScheduleWeekDaysPropsApiModel,
  SplashContentApiModel,
  SplashContentTypeApiModel,
  SplashLayoutApiModel,
  VideoModeApiModel,
} from 'src/services/device/apiModels';
import { CamelCase, ValueOf } from './global';

export type Device = DeviceApiModel;
export type DeviceInfo = DeviceInfoApiModel;

export { SplashLayoutApiModel as SplashLayout };
export { DevicePlatformApiModel as DevicePlatform };
export { DeviceStateApiModel as DeviceState };
export { FirmwareChannelApiModel as FirmwareChannel };
export { NetworkFrequencyBandApiModel as NetworkFrequencyBand };
export { ScheduleAutoRestartModeApiModel as ScheduleAutoRestartMode };
export { ScheduleStandbyModeApiModel as ScheduleStandbyMode };
export { DeviceSettingsStateApiModel as DeviceSettingsState };

export type DeviceNetworkInterface = DeviceNetworkInterfaceApiModel;
export type DeviceNetworkState = DeviceNetworkStateApiModel;
export type SplashContentType = SplashContentTypeApiModel;
export type SplashContent = SplashContentApiModel;
export type HomescreenOrientation = HomescreenOrientationApiModel;
export type DevicePeripherals = DevicePeripheralsApiModel;
export type ScheduleAutoRestartWeekDays = ScheduleAutoUpdateWeekDaysApiModel;
export type ScheduleStandbyConfig = ScheduleStandbyConfigApiModel;
export type ScheduleStandbyDisabledConfig = ScheduleStandbyDisabledConfigApiModel;
export type ScheduleStandbyScheduledConfig = ScheduleStandbyScheduledConfigApiModel;
export type ScheduleStandbyAutomaticConfig = ScheduleStandbyAutomaticConfigApiModel;
export type ScheduleAutoRestartDisabledConfig = ScheduleAutoRestartDisabledConfigApiModel;
export type ScheduleAutoRestartScheduledConfig = ScheduleAutoRestartScheduledConfigApiModel;
export type ScheduleAutoRestartConfig = ScheduleAutoRestartConfigApiModel;
export type ScheduleWeekDaysProps = ScheduleWeekDaysPropsApiModel;
export type ScheduleStandbyWeekDaysProps = ScheduleStandbyWeekDaysPropsApiModel;
export type ScheduleWeekDaysType = WeekDaysType | 'all';
export type DeviceGetToken = DeviceGetTokenApiModel;
export type PostDeviceOOBSettings = PostDeviceOOBSettingsApiRequest;
export type DeviceActivation = GetDeviceActivationApiResponse;
export type DeviceActivationPlan = DeviceActivationPlanApiModel;
export type DeviceActivationStatus = DeviceActivationStatusApiModel;
export type DeviceLatestVersions = DeviceLatestVersionApiModel;
export type DeviceVersionStatus = DeviceVersionStatusApiModel;
export type Resolution = ResolutionApiModel;
export type VideoMode = VideoModeApiModel;

export type { DeviceCommandApiModel as DeviceCommand };
export type { PostDeviceCommandApiResponse as DeviceCommandStatus };

export const DeviceCommands: {
  [K in DeviceCommandApiModel as CamelCase<K, '-'>]: K;
} = {
  reboot: 'reboot',
  stopStream: 'stop-stream',
  update: 'update',
} as const;

export const DevicePlatformFullName: Record<DevicePlatformApiModel, string> = {
  DG1: 'Airtame 1',
  DG2: 'Airtame 2',
  DG3: 'Airtame 3',
  CD1: 'Airtame Hub',
  VA_WIN: 'Windows',
  VA_ANDROID: 'Android',
  VA_ANDROID_SMART: 'Smart',
} as const;

export type DevicePlatformFullName = ValueOf<typeof DevicePlatformFullName>;

export const ConnectionStatus = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  TIMEOUT: 'timeout',
} as const;

export type ConnectionStatus = ValueOf<typeof ConnectionStatus>;

export const ConnectionMode = {
  AP: 'ap',
  CLIENT: 'client',
} as const;

export type ConnectionMode = ValueOf<typeof ConnectionMode>;

export type DeviceSignageContent = {
  id: number; // Template id
  type: 'legacy' | 'signage';
};

export type DeviceNetworkData = Pick<DeviceNetworkInterface, 'ip' | 'band' | 'mac'> & {
  network: string;
  rssi: number | string;
};

export type ThumbnailImage = { hash: number; image: string };
