import {
  ServiceIntegrationAction,
  ServiceIntegrationActionType,
} from 'src/actions/serviceIntegration/types';

export type ServiceIntegrationState = {
  googleAccessToken: string | null;
  googleEmail: string | null;
  microsoftAccessToken: string | null;
  microsoftEmail: string | null;
  isAuthenticating: boolean;
};

/**
 * initial state
 * @type {Object}
 */

const initialState = {
  googleAccessToken: null,
  googleEmail: null,
  microsoftAccessToken: null,
  microsoftEmail: null,
  isAuthenticating: false,
};

/**
 * Takes care of catching actions to update the device state
 * @public
 * @param  {Object} state  The device default state
 * @param  {Object} action An action to update the device state
 * @return {Object}        The updated device state
 */
export default function serviceIntegration(
  state: ServiceIntegrationState = initialState,
  action: ServiceIntegrationAction
): ServiceIntegrationState {
  switch (action.type) {
    case ServiceIntegrationActionType.AUTHENTICATE_GOOGLE_REQUEST:
      return Object.assign({}, state, {
        isAuthenticating: true,
      });
    case ServiceIntegrationActionType.AUTHENTICATE_GOOGLE_RESPONSE:
      return Object.assign({}, state, {
        isAuthenticating: false,
        googleEmail: action.googleEmail,
        googleAccessToken: action.accessToken,
      });
    case ServiceIntegrationActionType.AUTHENTICATE_GOOGLE_ERROR:
      return Object.assign({}, state, {
        isAuthenticating: false,
      });
    case ServiceIntegrationActionType.AUTHENTICATE_MICROSOFT_REQUEST:
      return Object.assign({}, state, {
        isAuthenticating: true,
      });
    case ServiceIntegrationActionType.AUTHENTICATE_MICROSOFT_RESPONSE:
      return Object.assign({}, state, {
        isAuthenticating: false,
        microsoftEmail: action.microsoftEmail,
        microsoftAccessToken: action.accessToken,
      });
    case ServiceIntegrationActionType.AUTHENTICATE_MICROSOFT_ERROR:
      return Object.assign({}, state, {
        isAuthenticating: false,
      });
    case ServiceIntegrationActionType.GET_GOOGLE_ACCESS_TOKEN_REQUEST:
      return Object.assign({}, state, {
        isAuthenticating: true,
      });
    case ServiceIntegrationActionType.GET_GOOGLE_ACCESS_TOKEN_RESPONSE:
      return Object.assign({}, state, {
        isAuthenticating: false,
        googleAccessToken: action.googleAccessToken,
      });
    case ServiceIntegrationActionType.GET_GOOGLE_ACCESS_TOKEN_ERROR:
      return Object.assign({}, state, {
        isAuthenticating: false,
      });
    case ServiceIntegrationActionType.GET_MICROSOFT_ACCESS_TOKEN_REQUEST:
      return Object.assign({}, state, {
        isAuthenticating: true,
      });
    case ServiceIntegrationActionType.GET_MICROSOFT_ACCESS_TOKEN_RESPONSE:
      return Object.assign({}, state, {
        isAuthenticating: false,
        microsoftAccessToken: action.microsoftAccessToken,
      });
    case ServiceIntegrationActionType.GET_MICROSOFT_ACCESS_TOKEN_ERROR:
      return Object.assign({}, state, {
        isAuthenticating: false,
      });

    default:
      return state;
  }
}
